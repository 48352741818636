<script setup lang="ts">
import {EventSourcePolyfill} from 'event-source-polyfill'
import Cookies from 'js-cookie'
import type {DrawerPlacement, UploadFileInfo} from 'naive-ui'
import {NSlider, useMessage} from 'naive-ui'
import {reactive, ref, watch} from 'vue'
import {v4 as uuidv4} from 'uuid'
// import VueMasonry from 'vue-masonry'
import {getPrivatePictureList} from '@/api'
import HeaderComponent from '@/views/chat/components/Header/index.vue'
import {useAppStore, useAuthStore} from '@/store'
import {useBasicLayout} from '@/hooks/useBasicLayout'
import axios from '@/utils/request/axios'
import {getPublicPictureList} from '@/api/index'
import defaultImg from '@/assets/loading.gif'
import {debounce} from 'lodash';
import {showLogin} from "@/hooks/LoginStatus";

const {isMobile} = useBasicLayout()
const appStore = useAppStore()

// defineComponent({
//   components: {
//     // eslint-disable-next-line vue/no-unused-components
//     VueMasonry,
//   },
// })
interface drawConfig {
  quality: number
  art: number
  abstract: number
  originalPrompt: string
  drawPrompt: string
}

const showModalRef = ref(false)
const previewImageUrlRef = ref('')
const base64 = ref('')

const showModal = showModalRef.value
const previewImageUrl = previewImageUrlRef.value

function handlePreview(file: UploadFileInfo) {
  const {url} = file
  previewImageUrlRef.value = url as string
  showModalRef.value = true
}

function handleRemove() {
  base64.value = ''
}

function handleGetBase64(file: UploadFileInfo[]) {
  if (file.length > 0) {
    let fileData = file[0].file;
    var reader = new FileReader()
    if (fileData != null) {
      reader.readAsDataURL(fileData)
      reader.onload = () => {
        console.log('file 转 base64结果：' + reader.result)
        base64.value = reader.result as string
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    }
  }
}

const previewFileList = ref<UploadFileInfo[]>([
  // {
  // 	id: 'react',
  // 	name: '我是react.png',
  // 	status: 'finished',
  // 	url: 'https://07akioni.oss-cn-beijing.aliyuncs.com/07akioni.jpeg'
  // },
  // {
  // 	id: 'vue',
  // 	name: '我是vue.png',
  // 	status: 'finished',
  // 	url: 'https://07akioni.oss-cn-beijing.aliyuncs.com/07akioni.jpeg'
  // }
])

const drawState: drawConfig = reactive({
  quality: 1.0,
  art: 300,
  abstract: 50,
  originalPrompt: '',
  drawPrompt: '',
})

const abstract = {
  50: '50',
}
const art = {
  300: '300',
}

const quality = {
  1: '1',
}

const authStore = useAuthStore()

// 图片比例
const checkRatio = ref<'1:1' | '4:3' | '3:4' | '16:9' | '9:16'>('1:1')

// 选择模型
const checkModel = ref<'niji 5' | '5' | '5.1' | '5.2'>('5.2')

// 效率模式
const efficiency = ref<'turbo' | 'fast' | 'relax'>('fast')
watch(
    isMobile,
    (val) => {
      appStore.setSiderCollapsed(val)
    },
    {
      immediate: true,
      flush: 'post',
    },
)
const eventSource = ref<EventSourcePolyfill>()
const taskId = ref<string>('')
const meta = ref<string>('')
const params = ref<string>('')
const active = ref(false)
const placement = ref<DrawerPlacement>('right')
const activate = (place: DrawerPlacement) => {
  active.value = true
  placement.value = place
}
const showProgress = ref<boolean>(false)
const showOperateDiv = ref<boolean>(false)
const page = ref<number>(1)
const total = ref<number>(0)
// 用户公开作品总数
const imgTotal = ref(0);
// 用户个人作品总数
const userImgTotal = ref(0);
const userPage = ref<number>(1)
const userTotal = ref<number>(0)
// 显示登录提示模态框
const picUrl = ref<string>('')
const message = useMessage()
// 用户公开作品图像列表
const imgList = ref<any>([])
// 用户公开作品图像列表
const userImgList = ref<any>([])
// 是否正在加载用户公开图像列表
const isLoading = ref<boolean>(false)
// 是否正在加载用户私有图像列表
const isUserLoading = ref<boolean>(false)
// 用户公开图像列表当前页码
const imgPageNum = ref(1)
// 用户公开图像列表当前页容量
const imgPageSize = ref(9)

// 用户图像列表当前页码
const userImgPageNum = ref(1)
// 用户图像列表当前页容量
const userImgPageSize = ref(9)
// 轮播图图片列表
const carouselImgList = ref([]);
const progress = ref('')

function checkString() {
  //模型版本
  if (drawState.drawPrompt.includes("--v ")) {
    const paramV = drawState.drawPrompt.split("--v ")[1].split(" ")[0];
    if (paramV === "5") {
      checkModel.value = '5'
    } else if (paramV === "5.1") {
      checkModel.value = '5.1'
    } else if (paramV === "5.2") {
      checkModel.value = '5.2'
    } else {
      checkModel.value = '5.2'
    }
  } else if (drawState.drawPrompt.includes("--niji ")) {
    checkModel.value = 'niji 5'
  }

  //图像比例
  if (drawState.drawPrompt.includes("--ar ")) {
    const paramAr = drawState.drawPrompt.split("--ar ")[1].split(" ")[0];
    if (paramAr === "1:1") {
      checkRatio.value = '1:1'
    } else if (paramAr === "4:3") {
      checkRatio.value = '4:3'
    } else if (paramAr === "3:4") {
      checkRatio.value = '3:4'
    } else if (paramAr === "16:9") {
      checkRatio.value = '16:9'
    } else if (paramAr === "9:16") {
      checkRatio.value = '9:16'
    } else {
      checkRatio.value = '1:1'
    }
  }
  //艺术程度
  if (drawState.drawPrompt.includes("--stylize ")) {
    const paramStylize = drawState.drawPrompt.split("--stylize ")[1].split(" ")[0];
    if (paramStylize >= 0 && paramStylize <= 1000) {
      drawState.art = Number(paramStylize)
    } else {
      drawState.art = 300
    }
  }

  //艺术程度
  if (drawState.drawPrompt.includes("--s ")) {
    const paramStylize = drawState.drawPrompt.split("--s ")[1].split(" ")[0];
    if (paramStylize >= 0 && paramStylize <= 1000) {
      drawState.art = Number(paramStylize)
    } else {
      drawState.art = 300
    }
  }

  //抽象程度
  if (drawState.drawPrompt.includes("--chaos ")) {
    const paramChaos = drawState.drawPrompt.split("--chaos ")[1].split(" ")[0];
    if (paramChaos >= 0 && paramChaos <= 100) {
      drawState.abstract = Number(paramChaos)
    } else {
      drawState.abstract = 10
    }
  }
  if (drawState.drawPrompt.includes("--c ")) {
    const paramChaos = drawState.drawPrompt.split("--c ")[1].split(" ")[0];
    if (paramChaos >= 0 && paramChaos <= 100) {
      drawState.abstract = Number(paramChaos)
    } else {
      drawState.abstract = 10
    }
  }

  //图片质量
  if (drawState.drawPrompt.includes("--q ")) {
    const paramQ = drawState.drawPrompt.split("--q ")[1].split(" ")[0];
    if (paramQ >= 0 && paramQ <= 2) {
      drawState.quality = Number(paramQ)
    } else {
      drawState.quality = 1.0
    }
  }
}

// 获取轮播图图片列表
function getCarouselList() {
  const params = {
    currentPage: 7,
    pageSize: 9,
    queryString: '',
  }
  getPublicPictureList(params).then((res) => {
    if (res.data.code === 200) {
      // const start = (imgPageNum.value - 1) * imgPageSize.value
      // const end = start + imgPageSize.value
      // imgList.value = imgList.value.concat(res.data.data.records.slice(start, end))
      carouselImgList.value = res.data.data.records
    }
  })
}

// 获取用户公开作品图像列表
function getImgList() {
  isLoading.value = true
  const params = {
    currentPage: imgPageNum.value,
    pageSize: imgPageSize.value,
    queryString: '',
  }
  getPublicPictureList(params).then((res) => {
    if (res.data.code === 200) {
      // const start = (imgPageNum.value - 1) * imgPageSize.value
      // const end = start + imgPageSize.value
      // imgList.value = imgList.value.concat(res.data.data.records.slice(start, end))
      imgList.value = res.data.data.records
      total.value = res.data.data.pages
      imgTotal.value = res.data.data.total
      isLoading.value = false
    }
  })
}

// 获取用户自己的作品图像列表
function getUserImgList() {
  isUserLoading.value = true
  const params = {
    currentPage: userImgPageNum.value,
    pageSize: userImgPageSize.value,
    queryString: '',
  }
  getPrivatePictureList(params).then((res) => {
    if (res.data.code === 200) {
      // const start = (imgPageNum.value - 1) * imgPageSize.value
      // const end = start + imgPageSize.value
      // imgList.value = imgList.value.concat(res.data.data.records.slice(start, end))
      console.log(res.data.data.records.length)
      userImgList.value = res.data.data.records
      userTotal.value = res.data.data.pages
      userImgTotal.value = res.data.data.total
      isUserLoading.value = false
    }
  })
}

// 监听窗口滚动事件
const handleScroll = () => {
  const scrollHeight = document.documentElement.scrollHeight
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
  const clientHeight = document.documentElement.clientHeight
  if (scrollTop + clientHeight >= scrollHeight && !isLoading.value) {
    imgPageNum.value++
    getImgList()
  }
}
getCarouselList()
getImgList()
getUserImgList()

const isEnabledTranslate = ref<boolean>(false)

function handleTranslate() {
  if (drawState.originalPrompt.trim().length === 0) {
    return
  }
  isEnabledTranslate.value = true
  isEnabledHzy.value = true
  const clientId = uuidv4()
  const token = Cookies.get('token')
  const config = {
    automaticReconnection: false,
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'clientid': clientId,
    },
  }
  eventSource.value = new EventSourcePolyfill(
      '/api/clientRegister',
      config,
  )

  if (eventSource.value instanceof EventSourcePolyfill) {
    eventSource.value.onopen = (event: { target: any }) => {
      console.log('已连接到服务器 -> 等待响应')

      axios.post('/toConversationByDraw',
          {
            prompt: `你将充当一个专业的翻译官，根据我的输入简单的翻译为英文，并直接输出翻译结果,需要使用 ',' 隔开\nuser:一个小女孩\nai:1girl,\nuser:一个小男孩\nai:1boy,\nuser:一个小女孩和一个小男孩\nai:1girl,1boy,\nuser:${drawState.originalPrompt}\nai:`,
            sessionId: clientId,
            network: false,
            withoutContext: true,
            system: '',
          }, config)
      drawState.drawPrompt = ''
    }
  }

  if (eventSource.value instanceof EventSourcePolyfill) {
    eventSource.value.onerror = (event: any) => {
      const status = event.status
      if (status === 401) {
        message.info('请登录后再使用本功能')
        authStore.removeToken()
        Cookies.remove('token')
        showLogin.value = true
      }
      isEnabledTranslate.value = false
      isEnabledHzy.value = false

      message.warning("服务器连接建立失败，请稍后再试。")
      eventSource.value?.close()
    }
  }

  const text = ''

  if (eventSource.value instanceof EventSourcePolyfill) {
    eventSource.value.onmessage = (event: {
      lastEventId: string
      data: string
    }) => {
      if (event.lastEventId === '[TOKENS]') {
        drawState.drawPrompt = text + event.data
        // setText(text, uuid_str)
        // text = ''
        return
      }
      if (event.data === '[DONE]') {
        if (eventSource.value) {
          if (eventSource.value instanceof EventSourcePolyfill) {
            eventSource.value.close()
          }
        }
        isEnabledTranslate.value = false
        isEnabledHzy.value = false
        return
      }

      const json_data = JSON.parse(event.data)
      if (json_data.content == null || json_data.content === 'null') {
        return
      }

      drawState.drawPrompt += json_data.content
    }
  }
}

const isEnabledHzy = ref<boolean>(false)

function handleAiOptimize() {
  if (drawState.originalPrompt.trim().length === 0) {
    message.info("请输入你的绘画灵感，再翻译为英文")
    return
  }
  isEnabledHzy.value = true
  isEnabledTranslate.value = true
  hzy()
}

function hzy() {
  const clientId = uuidv4()
  const token = Cookies.get('token')
  const config = {
    automaticReconnection: false,
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'clientid': clientId,
    },
  }
  eventSource.value = new EventSourcePolyfill(
      '/api/clientRegister',
      config,
  )

  if (eventSource.value instanceof EventSourcePolyfill) {
    eventSource.value.onopen = (event: { target: any }) => {
      console.log('已连接到服务器 -> 等待响应')

      axios.post('/toConversationByDraw',
          {
            prompt: `你需要为我生成Midjourney绘画提示词，回答的形式是：\n(image we're prompting), (7 descriptivekeywords), (camera type), (camera lenstype), (time of day), (style of photograph)(type of film), (Hd degree).\n这是一段段按照上述形式的示例问答：\n问题：\n参考以上midjoruney prompt formula写1个midjourney prompt内容，用英文回复，不要括号，内容：宫崎骏风格的春天小镇\n回答：\n英文：Miyazaki Hayao-style town,Green willow and red flowers, breeze coming, dreamy colors, fantastic elements, fairy-tale situation, warm breath, smooth picture, telephoto lens, shooting in the evening, positive film, 4K ultra HD\n现在严格参考以上的示例回答形式和风格（这很重要），根据以下的内容生成提示词(直接以英文输出，需要补全):${drawState.originalPrompt}`,
            sessionId: clientId,
            network: false,
            withoutContext: true,
            system: 'I want you to act as a prompt generator for Midjourney\'s artificial intelligence program. Your job is to provide detailed and creative descriptions that will inspire unique and interesting images from the AI. Keep in mind that the AI is capable of understanding a wide range of language and can interpret abstract concepts, so feel free to be as imaginative and descriptive as possible.',
          }, config)
      drawState.drawPrompt = ''
    }
  }

  if (eventSource.value instanceof EventSourcePolyfill) {
    eventSource.value.onerror = (event: any) => {
      const status = event.status
      if (status === 401) {
        message.info('请登录后再使用本功能')
        authStore.removeToken()
        Cookies.remove('token')
        showLogin.value = true
      }
      isEnabledHzy.value = false
      isEnabledTranslate.value = false
    }
  }

  const text = ''

  if (eventSource.value instanceof EventSourcePolyfill) {
    eventSource.value.onmessage = (event: {
      lastEventId: string
      data: string
    }) => {
      if (event.lastEventId === '[TOKENS]') {
        drawState.drawPrompt = text + event.data
        // setText(text, uuid_str)
        // text = ''
        return
      }
      if (event.data === '[DONE]') {
        if (eventSource.value) {
          if (eventSource.value instanceof EventSourcePolyfill) {
            eventSource.value.close()
          }
        }
        isEnabledHzy.value = false
        isEnabledTranslate.value = false
        yyz()
        return
      }

      const json_data = JSON.parse(event.data)
      if (json_data.content == null || json_data.content === 'null') {
        return
      }
      drawState.drawPrompt += json_data.content
    }
  }
}

function yyz() {
  const clientId = uuidv4()
  const token = Cookies.get('token')
  const config = {
    automaticReconnection: false,
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'clientid': clientId,
    },
  }
  eventSource.value = new EventSourcePolyfill(
      '/api/clientRegister',
      config,
  )

  if (eventSource.value instanceof EventSourcePolyfill) {
    eventSource.value.onopen = (event: { target: any }) => {
      console.log('已连接到服务器 -> 等待响应')

      axios.post('/toConversationByDraw',
          {
            prompt: `请您帮我将这段内容翻译为中文，并不要做任何解释，只给我输出中文：${drawState.drawPrompt}`,
            sessionId: clientId,
            network: false,
            withoutContext: true,
            system: '您现在是一名翻译',
          }, config).then((response) => {
        const result = response.data
        if (result.code === 200) {
          isEnabledHzy.value = true
          isEnabledTranslate.value = true
        }
      }).catch((error) => {
        isEnabledHzy.value = false
        isEnabledTranslate.value = false
      })
      drawState.originalPrompt = ''
    }
  }

  if (eventSource.value instanceof EventSourcePolyfill) {
    eventSource.value.onerror = (event: any) => {
      const status = event.status
      if (status === 401) {
        message.info('请登录后再使用本功能')

        authStore.removeToken()
        Cookies.remove('token')
        showLogin.value = true
      }
      event.target.close();
    }
  }

  const text = ''

  if (eventSource.value instanceof EventSourcePolyfill) {
    eventSource.value.onmessage = (event: {
      lastEventId: string
      data: string
    }) => {
      if (event.lastEventId === '[TOKENS]') {
        drawState.originalPrompt = text + event.data
        // setText(text, uuid_str)
        // text = ''
        return
      }
      if (event.data === '[DONE]') {
        if (eventSource.value) {
          if (eventSource.value instanceof EventSourcePolyfill) {
            eventSource.value.close()
          }
        }
        isEnabledHzy.value = false
        isEnabledTranslate.value = false
        return
      }

      const json_data = JSON.parse(event.data)
      if (json_data.content == null || json_data.content === 'null') {
        return
      }
      drawState.originalPrompt += json_data.content
    }
  }
}

function handleDel(id: string) {
  const token = Cookies.get('token')
  const config = {
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }
  axios.delete(`/mjDraw/delPicture/${id}`, config)
      .then((response) => {
        const result = response.data
        if (result.data === 200) {
          message.success("删除成功")
          getPrivatePic()
        }

      }).catch((error) => {
    message.error("删除失败，请稍后再试")

    console.log(error)
  })
}

function handleShare(id: string) {
  const token = Cookies.get('token')
  const config = {
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }
  axios.get(`/mjDraw/askShare/${id}`, config)
      .then((response) => {
        const result = response.data
        if (result.data === 200) {
          message.info(result.msg)
          getPrivatePic()
        } else if (result.data === 400) {
          message.warning(result.msg)
        }
      }).catch((error) => {
    console.log(error)
  })
}

//派生
function handleEditPic(item) {
  console.log(item)
  picUrl.value = item.pictureUrl
  hasRunTask.value = true
  drawConfigDiv.value = false
  showOperateDiv.value = true
  taskId.value = item.id
  currentStep.value = 2;
}

function handleCopy(prompt: string) {
  const token = Cookies.get('token')
  const config = {
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }
  navigator.clipboard.writeText(prompt)
      .then(() => {
        message.success('复制成功')
      })
      .catch((error) => {
        message.error('复制失败')
      });

}

const condition = ref(false);
const reCondition = ref(false);
const hasRunTask = ref(false);

const handleRunDrawMethod = debounce(() => {
  // 在这里编写需要进行防抖的代码
  handleRunDraw()
}, 1000); // 设置防抖的延迟时间，单位为毫秒

function handleRunDraw() {
  if (drawState.drawPrompt.trim().length === 0) {
    message.info("请输入英文咒语")
    return
  }

  //提示词非法
  if (drawState.drawPrompt.trim() === 'AI绘画功能依赖GPT大模型，请先确保你拥有GPT3.5或GPT4.0会员权限。') {
    message.warning("请输入你想绘画的实物：例如：a dog")
    return
  }
  //提示词非法
  if (drawState.drawPrompt.trim().includes('请先确保你拥有GPT3.5或GPT4') || drawState.drawPrompt.trim().includes('依赖GPT大模型')) {
    message.warning("请输入你想绘画的实物：例如：a dog")
    return
  }


  // 提取 --v 的参数 版本
  if (drawState.drawPrompt.includes("--v ")) {
    const paramV = drawState.drawPrompt.split("--v ")[1].split(" ")[0];
    drawState.drawPrompt = drawState.drawPrompt.replace(paramV, checkModel.value);
  } else if (checkModel.value === "niji 5") {
    params.value += ` --niji 5 `
  } else {
    params.value += `--v ${checkModel.value} `
  }

  // 提取 --ar 的参数 图像比例
  if (drawState.drawPrompt.includes("--ar ")) {
    const paramAr = drawState.drawPrompt.split("--ar ")[1].split(" ")[0];
    drawState.drawPrompt = drawState.drawPrompt.replace(paramAr, checkRatio.value);
  } else {
    params.value += `--ar ${checkRatio.value} `
  }

  //艺术程度
  if (drawState.drawPrompt.includes("--stylize ")) {
    const paramStylize = drawState.drawPrompt.split("--stylize ")[1].split(" ")[0];
    drawState.drawPrompt = drawState.drawPrompt.replace(paramStylize, String(drawState.art));
  }

  //艺术程度
  if (drawState.drawPrompt.includes("--s ")) {
    const paramStylize = drawState.drawPrompt.split("--s ")[1].split(" ")[0];
    drawState.drawPrompt = drawState.drawPrompt.replace(paramStylize, String(drawState.art));
  } else {
    params.value += `--s ${drawState.art} `
  }

  // 提取 --q 的参数 图片质量
  if (drawState.drawPrompt.includes("--q ")) {
    const paramQ = drawState.drawPrompt.split("--q ")[1].split(" ")[0];
    drawState.drawPrompt = drawState.drawPrompt.replace(paramQ, String(Number(drawState.quality)));
  } else {
    params.value += `--q ${String(Number(drawState.quality))} `
  }

  // 提取 --c 的参数 抽象程度
  if (drawState.drawPrompt.includes("--chaos ")) {
    const paramChaos = drawState.drawPrompt.split("--chaos ")[1].split(" ")[0];
    drawState.drawPrompt = drawState.drawPrompt.replace(paramChaos, String(Number(drawState.abstract)));
  } else {
    params.value += `--c ${String(Number(drawState.abstract))} `
  }
  activate('right')
  const token = Cookies.get('token')
  const config = {
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }

  axios.post('/mjDraw/drawPicture',
      {
        prompt: `${drawState.drawPrompt} ${params.value}`,
        base64: base64.value,
      }, config)
      .then((response) => {
        const result = response.data
        console.log(result.code)
        if (result.code === 200) {
          // const data = result.data
          // const parse = JSON.parse(data)
          // if (parse.code === 1) {
          // 	taskId.value = parse.result
          // }
          //暂无权限使用AI会话
          if (result.data === 10010) {
            message.warning(result.msg)
            return
          }
          resetDivStatus()
          hasRunTask.value = true
          taskId.value = result.data
          console.log(`任务创建成功：${result.data}`)
          showOperateDiv.value = false
          showProgress.value = true
          condition.value = false
          drawState.drawPrompt = ""
          base64.value = ''
          params.value = ''
          getUserImgList()
          let timer;
          timer = setInterval(() => {
            // 每5秒执行一次的逻辑
            axios.get(`/mjDraw/getPicture/${taskId.value}?date=${Date.now()}`, config)
                .then((response) => {
                  const result = response.data
                  if (result.code === 200) {
                    picUrl.value = result.data.imageUrl === "" ? defaultImg : (result.data.imageUrl + '?' + new Date().getTime());
                    currentStep.value = 2;

                    if (result.data.progress === "100%") {
                      message.success("绘画流程结束")
                      showProgress.value = false
                      getUserImgList()
                      condition.value = true
                      showOperateDiv.value = true
                      // hasRunTask.value = false
                    }
                    progress.value = result.data.progress.replace("%", "");
                    if (result.data.failReason !== "") {
                      condition.value = true
                      message.warning("绘画任务执行失败，请更换提示词后重试一次")
                      hasRunTask.value = true
                      currentStep.value = 1;
                    }
                  }
                }).catch((error) => {
              console.log(error)
              condition.value = true
              message.warning("绘画任务执行失败，请更换提示词后重试一次")
              hasRunTask.value = false
              currentStep.value = 1;
            })

            if (condition.value) {
              clearInterval(timer);
            }
          }, 5000);
        }
        if (result.code == 400) {
          message.warning(result.msg)
        }
      }).catch((error) => {
    const result = error.response
    console.log(result)
    if (result.status === 400) {
      message.warning(result.msg)
      return;
    }
    if (result.status === 401) {
      message.info('请登录后再使用本功能')
      authStore.removeToken()
      Cookies.remove('token')
      showLogin.value = true
    }
  })

}

const drawConfigDiv = ref(true)

function resetDivStatus() {
  drawConfigDiv.value = !drawConfigDiv.value
}

// 获取公开图片列表
function getPublicPic() {
  const param = {
    currentPage: page.value,
    pageSize: 9,
    queryString: '',
  }
  isLoading.value = true
  getPublicPictureList(param).then((res) => {
    if (res.data.code === 200) {
      // const start = (imgPageNum.value - 1) * imgPageSize.value
      // const end = start + imgPageSize.value
      // imgList.value = imgList.value.concat(res.data.data.records.slice(start, end))
      imgList.value = res.data.data.records
      total.value = res.data.data.pages
      isLoading.value = false
    }
  })
}

// const paramVnew = ref<string>('')

// function handleCheckModel() {
// 	if (drawState.drawPrompt.includes("--v ")) {
// 		const paramV = drawState.drawPrompt.split("--v ")[1].split(" ")[0];
// 		let v = drawState.drawPrompt.replace(paramV, checkModel.value);
// 		paramVnew.value = v.split("--v ")[1].split(" ")[0];
// 	} else if (checkModel.value === "niji 5") {
// 		paramVnew.value = ` --niji 5 `
// 	} else {
// 		paramVnew.value = `--v ${checkModel.value} `
// 	}
// }

// const paramArrNew = ref<string>('')
//
// function handleCheckRatio() {
// 	if (drawState.drawPrompt.includes("--ar ")) {
// 		const paramV = drawState.drawPrompt.split("--ar ")[1].split(" ")[0];
// 		let ar = drawState.drawPrompt.replace(paramV, checkRatio.value);
// 		paramVnew.value = ar.split("--ar ")[1].split(" ")[0];
// 	} else {
// 		paramArrNew.value = `--ar ${checkRatio.value} `
// 	}
// }

// 获取私有图片列表
function getPrivatePic() {
  const param = {
    currentPage: userPage.value,
    pageSize: 9,
    queryString: '',
  }
  isUserLoading.value = true
  getPrivatePictureList(param).then((res) => {
    if (res.data.code === 200) {
      // const start = (imgPageNum.value - 1) * imgPageSize.value
      // const end = start + imgPageSize.value
      // imgList.value = imgList.value.concat(res.data.data.records.slice(start, end))
      userImgList.value = res.data.data.records
      userTotal.value = res.data.data.pages
      isUserLoading.value = false
    }
  })
}

//重绘
function handleRebuild(type: number) {

  console.log(taskId.value)
  if (!taskId.value) {
    message.warning("请刷新页面后再试")
    return
  }

  const token = Cookies.get('token')
  const config = {
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }
  //重绘
  if (type === 9) {
    axios.post('/mjDraw/rebuild',
        {
          drawId: taskId.value,
          action: 'REROLL',
          index: ''
        }, config)
        .then((response) => {
          const result = response.data
          if (result.code === 200) {
            // const data = result.data
            // const parse = JSON.parse(data)
            // if (parse.code === 1) {
            // 	taskId.value = parse.result
            // }
            //暂无权限使用AI会话
            if (result.data === 10010) {
              message.warning(result.msg)
              return
            }

            hasRunTask.value = true
            taskId.value = result.data
            console.log(`任务创建成功：${result.data}`)
            showProgress.value = true
            showOperateDiv.value = false
            reCondition.value = false
            drawState.drawPrompt = ""
            params.value = ''
            getUserImgList()
            let reTimer;
            reTimer = setInterval(() => {
              // 每5秒执行一次的逻辑
              axios.get(`/mjDraw/getPicture/${taskId.value}?date=${Date.now()}`, config)
                  .then((response) => {
                    const result = response.data
                    console.log()
                    if (result.code === 200) {
                      picUrl.value = result.data.imageUrl === null ? defaultImg : (result.data.imageUrl + '?' + new Date().getTime());
                      currentStep.value = 2;
                      if (result.data.progress === "100%") {
                        message.success("绘画流程结束")
                        showProgress.value = false
                        getUserImgList()
                        reCondition.value = true
                        hasRunTask.value = false
                        showOperateDiv.value = true
                        resetDivStatus()
                      }
                      progress.value = result.data.progress.replace("%", "");
                      if (result.data.failReason !== "") {
                        reCondition.value = true
                        message.warning("绘画任务执行失败，请更换提示词后重试一次")
                        hasRunTask.value = true
                        currentStep.value = 1;
                        resetDivStatus()
                      }
                    }
                  }).catch((error) => {
                console.log(error)
                reCondition.value = true
                message.warning("绘画任务执行失败，请更换提示词后重试一次")
                hasRunTask.value = true
                currentStep.value = 1;
                resetDivStatus()
              })

              if (reCondition.value) {
                clearInterval(reTimer);
              }
            }, 5000);

          }
        }).catch((error) => {
      console.log(error)
    })
  } else {
    let action = ""
    let index = 1

    if (type === 1) {
      action = "VARIATION"
      index = 1
    } else if (type === 2) {
      action = "VARIATION"
      index = 2
    } else if (type === 3) {
      action = "VARIATION"
      index = 3
    } else if (type === 4) {
      action = "VARIATION"
      index = 4
    } else if (type === 5) {
      action = "UPSCALE"
      index = 1
    } else if (type === 6) {
      action = "UPSCALE"
      index = 2
    } else if (type === 7) {
      action = "UPSCALE"
      index = 3
    } else if (type === 8) {
      action = "UPSCALE"
      index = 4
    } else {
      message.error("参数错误")
      return;
    }

    axios.post('/mjDraw/rebuild',
        {
          drawId: taskId.value,
          action: action,
          index: index
        }, config)
        .then((response) => {
          const result = response.data
          if (result.code === 200) {
            // const data = result.data
            // const parse = JSON.parse(data)
            // if (parse.code === 1) {
            // 	taskId.value = parse.result
            // }
            //暂无权限使用AI会话
            if (result.data === 10010) {
              message.warning(result.msg)
              return
            }

            hasRunTask.value = true
            taskId.value = result.data
            console.log(`任务创建成功：${result.data}`)
            showOperateDiv.value = false
            showProgress.value = true
            reCondition.value = false
            drawState.drawPrompt = ""
            params.value = ''
            getUserImgList()
            let reTimer;
            reTimer = setInterval(() => {
              // 每5秒执行一次的逻辑
              axios.get(`/mjDraw/getPicture/${taskId.value}?date=${Date.now()}`, config)
                  .then((response) => {
                    const result = response.data
                    console.log()
                    if (result.code === 200) {
                      picUrl.value = result.data.imageUrl === null ? defaultImg : (result.data.imageUrl + '?' + new Date().getTime());
                      currentStep.value = 2;
                      if (result.data.progress === "100%") {
                        message.success("绘画流程结束")
                        getUserImgList()
                        reCondition.value = true
                        hasRunTask.value = false
                        showOperateDiv.value = true
                        showProgress.value = false
                        resetDivStatus()

                      }
                      progress.value = result.data.progress.replace("%", "");
                      if (result.data.failReason !== "") {
                        reCondition.value = true
                        message.warning("绘画任务执行失败，请更换提示词后重试一次")
                        hasRunTask.value = false
                        currentStep.value = 1;
                        resetDivStatus()
                      }
                    }
                  }).catch((error) => {
                console.log(error)
                reCondition.value = true
                message.warning("绘画任务执行失败，请更换提示词后重试一次")
                hasRunTask.value = false
                currentStep.value = 1;
                resetDivStatus()
              })

              if (reCondition.value) {
                clearInterval(reTimer);
              }
            }, 5000);

          }
        }).catch((error) => {
      console.log(error)
    })
  }
}

const currentStep = ref<number | undefined>(1)
</script>

<template>
  <HeaderComponent
      v-if="isMobile"
  />
  <div class="draw-box bg-neutral-100" :class="[isMobile ? '' : 'flex']">
    <div :class="[isMobile ? '' : 'draw-list']">
      <n-tabs type="segment" v-if="!isMobile">
        <n-tab-pane name="chap1" tab="用户公开作品">
          <div>
            <div class="masonry">
              <div v-for="item in imgList" :key="item.id" class="masonry-item">
                <!-- 展示列表项的内容 -->
                <n-image
                    style="border-radius: 10px;"
                    :title="item.id"
                    width="300"
                    height="100%"
                    :src="item.picUrl"
                />
                <div class="img-bottom">
                  <n-button tertiary size="tiny" type="primary" @click="handleCopy(item.prompt)">
                    复制咒语
                  </n-button>
                </div>

              </div>
            </div>
            <div v-if="isLoading" class="loading">
              Loading...
            </div>
            <n-pagination
                style="border-radius: 10px;"
                v-if="imgList.length!=0"
                v-model:page="page"
                class="pageClass"
                :page-count="total"
                simple
                @update:page="getPublicPic()"
            />
          </div>
        </n-tab-pane>
        <n-tab-pane name="chap2" tab="我的创作">
          <div>
            <div class="masonry">
              <div v-for="item in userImgList" :key="item.id" class="masonry-item">
                <!-- 展示列表项的内容 -->
                <n-image
                    style="border-radius: 10px;"
                    :title="item.id"
                    width="300"
                    height="100%"
                    :src="item.pictureUrl===null || item.pictureUrl===''? defaultImg :item.pictureUrl"
                />
                <div class="img-bottom">
                  <n-tag v-if="item.taskStatus ===0" size="small" type="warning">
                    生成中
                  </n-tag>
                  <n-tag v-if="item.taskStatus ===2" size="small" type="error">
                    失败
                  </n-tag>
                  <n-button v-if="item.parentId==null && item.taskStatus !==2" tertiary size="tiny" type="info"
                            @click="handleEditPic(item)"
                  >
                    派生
                  </n-button>
                  <n-button tertiary size="tiny" type="info" @click="handleCopy(item.prompt)">
                    复制咒语
                  </n-button>
                  <n-button tertiary size="tiny" type="primary" @click="handleShare(item.id)">
                    {{ item.hasPublic === 0 ? '公开预览' : '取消公开' }}
                  </n-button>
                  <n-button tertiary size="tiny" type="error" @click="handleDel(item.id)">
                    删除
                  </n-button>
                </div>
              </div>
            </div>
            <div v-if="isUserLoading" class="loading">
              Loading...
            </div>
            <n-pagination
                v-if="userImgList.length!=0"
                v-model:page="userPage"
                class="pageClass"
                :page-count="userTotal"
                simple
                @update:page="getPrivatePic()"
            />
          </div>
        </n-tab-pane>
      </n-tabs>
      <div v-if="isMobile">
        <n-carousel autoplay style="margin-bottom:1em">
          <img class="carousel-img" v-for="item in carouselImgList" :key="item.id" :src="item.picUrl"/>
        </n-carousel>
        <n-collapse>
          <n-collapse-item name="1">
            <template #header>
              <svg t="1689002835584" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="1976" width="16" height="16">
                <path
                    d="M512 64.47C264.83 64.47 64.47 264.83 64.47 512S264.83 959.53 512 959.53 959.53 759.17 959.53 512 759.17 64.47 512 64.47zM758.14 696.8c0 48.73-40.06 88.22-89.52 88.22s-89.49-39.49-89.49-88.22c0-0.31 0.07-0.65 0.1-1s-0.1-0.65-0.1-1a9.49 9.49 0 0 1 0.14-1.43L421.63 579.19a89.62 89.62 0 0 1-66.24 29.38c-49.47 0-89.53-39.52-89.53-88.26 0-0.3 0.09-0.61 0.09-0.95s-0.09-0.65-0.09-1c0-48.74 40.06-88.23 89.53-88.23a89.85 89.85 0 0 1 62.82 25.46l163.62-106.47a81.67 81.67 0 0 1-2.73-20c0-0.24 0.07-0.51 0.07-0.82s-0.07-0.71-0.07-1.12c0-48.74 40.07-88.23 89.49-88.23s89.52 39.49 89.52 88.23c0 0.3-0.1 0.61-0.1 1s0.1 0.65 0.1 1c0 48.74-40.06 88.26-89.52 88.26a89.63 89.63 0 0 1-64.82-27.71L441.28 495.46a83.45 83.45 0 0 1 3.59 22.94c0 0.37-0.07 0.71-0.07 1.09s0.07 0.55 0.07 0.85a84.46 84.46 0 0 1-2.39 18.94l150.44 109a89.46 89.46 0 0 1 75.7-41.64c49.46 0 89.52 39.52 89.52 88.26 0 0.28-0.1 0.65-0.1 1s0.1 0.56 0.1 0.9z"
                    fill="#48E1AA" p-id="1977"></path>
                <path
                    d="M959.53 512a445.88 445.88 0 0 0-40.83-187 452.1 452.1 0 0 0-302.9-115.9C365.17 209.1 162 412.27 162 662.89a452.78 452.78 0 0 0 29.61 161.58A446.22 446.22 0 0 0 512 959.53c247.17 0 447.53-200.36 447.53-447.53z m-604.14 96.57c-49.47 0-89.53-39.52-89.53-88.26 0-0.3 0.09-0.61 0.09-0.95s-0.09-0.65-0.09-1c0-48.74 40.06-88.23 89.53-88.23a89.85 89.85 0 0 1 62.82 25.46l163.62-106.47a81.67 81.67 0 0 1-2.73-20c0-0.24 0.07-0.51 0.07-0.82s-0.07-0.71-0.07-1.12c0-48.74 40.07-88.23 89.49-88.23s89.52 39.49 89.52 88.23c0 0.3-0.1 0.61-0.1 1s0.1 0.65 0.1 1c0 48.74-40.06 88.26-89.52 88.26a89.63 89.63 0 0 1-64.82-27.71L441.28 495.46a83.45 83.45 0 0 1 3.59 22.94c0 0.37-0.07 0.71-0.07 1.09s0.07 0.55 0.07 0.85a84.46 84.46 0 0 1-2.39 18.94l150.44 109a89.46 89.46 0 0 1 75.7-41.64c49.46 0 89.52 39.52 89.52 88.26 0 0.28-0.1 0.65-0.1 1s0.1 0.65 0.1 1c0 48.73-40.06 88.22-89.52 88.22s-89.49-39.49-89.49-88.22c0-0.31 0.07-0.65 0.1-1s-0.1-0.65-0.1-1a9.49 9.49 0 0 1 0.14-1.43L421.63 579.19a89.62 89.62 0 0 1-66.24 29.38z"
                    fill="#3CD38E" p-id="1978"></path>
                <path
                    d="M668.59 417.41a89.57 89.57 0 0 1-64.17-27.06l-2.55 0.58-160.59 104.53a83.45 83.45 0 0 1 3.59 22.94c0 0.37-0.07 0.71-0.07 1.09s0.07 0.55 0.07 0.85a84.46 84.46 0 0 1-2.39 18.94l150.44 109a89.46 89.46 0 0 1 75.7-41.64c49.46 0 89.52 39.52 89.52 88.26 0 0.28-0.1 0.65-0.1 1s0.1 0.65 0.1 1c0 48.73-40.06 88.22-89.52 88.22s-89.49-39.49-89.49-88.22c0-0.31 0.07-0.65 0.1-1s-0.1-0.65-0.1-1a9.49 9.49 0 0 1 0.14-1.43L421.63 579.19a89.62 89.62 0 0 1-66.24 29.38 90.89 90.89 0 0 1-24.19-3.26 405.87 405.87 0 0 0-22 305.75A445.67 445.67 0 0 0 512 959.53c247.17 0 447.53-200.36 447.53-447.53q0-16.68-1.21-33.07A402.84 402.84 0 0 0 739.51 383a89.81 89.81 0 0 1-70.92 34.41z"
                    fill="#1DCE75" p-id="1979"></path>
                <path
                    d="M945.57 623.35a406.86 406.86 0 0 0-241.14-9.42c31.62 13.63 53.71 44.73 53.71 80.92 0 0.28-0.1 0.65-0.1 1s0.1 0.65 0.1 1c0 48.73-40.06 88.22-89.52 88.22s-89.49-39.49-89.49-88.22c0-0.31 0.07-0.65 0.1-1s-0.1-0.65-0.1-1a9.49 9.49 0 0 1 0.14-1.43l-14.6-10.59a404.3 404.3 0 0 0-155.35 264.86A448.85 448.85 0 0 0 512 959.53c208.72 0 384.07-142.89 433.57-336.18z"
                    fill="#05C46D" p-id="1980"></path>
                <path
                    d="M411.22 151.84c7.56 11.35-32.21 25.37-74.16 53.32s-70.2 59.25-77.76 47.91 15.63-50.24 57.58-78.19 86.78-34.39 94.34-23.04z"
                    fill="#FFFFFF" p-id="1981"></path>
              </svg>
              <n-text style="margin-left: 0.5em" type="success">
                用户公开作品
              </n-text>
            </template>
            <template #header-extra>
              <n-text type="success">
                作品总数：{{ imgTotal }}
              </n-text>
            </template>
            <div>
              <div class="masonry">
                <div v-for="item in imgList" :key="item.id" class="masonry-item-mobile">
                  <!-- 展示列表项的内容 -->
                  <img :title="item.id" style="height: 100%;border-radius: 10px;" :src="item.picUrl"/>
                  <div class="img-bottom-mobile">
                    <n-button tertiary size="tiny" type="primary" @click="handleCopy(item.prompt)">
                      复制咒语
                    </n-button>
                  </div>
                </div>
              </div>
              <div v-if="isLoading" class="loading">
                Loading...
              </div>
              <n-pagination
                  v-if="imgList.length!=0"
                  v-model:page="page"
                  class="pageClass"
                  :page-count="total"
                  simple
                  @update:page="getPublicPic()"
              />
            </div>
          </n-collapse-item>
          <n-collapse-item name="2">
            <template #header>
              <svg t="1689002736286" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="1488" width="16" height="16">
                <path
                    d="M506.72 63.78c-204.2 2.84-368.35 171.39-366 375.6A370.45 370.45 0 0 0 267 714.08a1.91 1.91 0 0 0 0.8 0.41 1.8 1.8 0 0 0-0.39 1.11c0 135.11 109.53 244.64 244.64 244.64s244.59-109.51 244.59-244.62a1.8 1.8 0 0 0-0.39-1.11 1.91 1.91 0 0 0 0.8-0.41 370.44 370.44 0 0 0 126.29-279c0-206.86-169.11-374.2-376.62-371.32zM461 505.9l0.47-1.21h0.58z m194.42-104.48L482.66 646.16a8.63 8.63 0 0 1-6.3 2.7 3.75 3.75 0 0 1-2.7-0.9c-4.2-1.22-5.7-3.6-4.5-7.2l27.9-164.66H375.58q-4.49 0-6.3-3.6-2.68-3.57 0.91-8.1l187.15-244.74c2.4-3.6 5.1-4.18 8.1-1.8 3 0.6 4.5 3 4.5 7.2L542 389.72h107.12c3 0 5.1 1.22 6.3 3.6a18.4 18.4 0 0 1 0 8.1z"
                    fill="#48E1AA" p-id="1489"></path>
                <path
                    d="M815.42 221a460.67 460.67 0 0 0-185-38.5c-235 0-429 175.32-458.38 402.28A372.57 372.57 0 0 0 267 714.08a1.91 1.91 0 0 0 0.8 0.41 1.8 1.8 0 0 0-0.39 1.11c0 135.11 109.53 244.64 244.64 244.64s244.59-109.51 244.59-244.62a1.8 1.8 0 0 0-0.39-1.11 1.91 1.91 0 0 0 0.8-0.41A371.39 371.39 0 0 0 815.42 221zM461 505.9l0.47-1.21h0.58z m194.42-104.48L482.66 646.16a8.63 8.63 0 0 1-6.3 2.7 3.75 3.75 0 0 1-2.7-0.9c-4.2-1.22-5.7-3.6-4.5-7.2l27.9-164.66H375.58q-4.49 0-6.3-3.6-2.68-3.57 0.91-8.1l187.15-244.74c2.4-3.6 5.1-4.18 8.1-1.8 3 0.6 4.5 3 4.5 7.2L542 389.72h107.12c3 0 5.1 1.22 6.3 3.6a18.4 18.4 0 0 1 0 8.1z"
                    fill="#3CD38E" p-id="1490"></path>
                <path
                    d="M687.77 391.85q-16.38 0-32.43 1.34a0.57 0.57 0 0 1 0.08 0.13 18.4 18.4 0 0 1 0 8.1L482.66 646.16a8.63 8.63 0 0 1-6.3 2.7 3.75 3.75 0 0 1-2.7-0.9c-4.2-1.22-5.7-3.6-4.5-7.2l27.9-164.66h-51.88c-90.64 71.68-148.81 182.62-148.81 307.14a393.7 393.7 0 0 0 3.82 54.85A244.54 244.54 0 0 0 512 960.26c135.11 0 244.64-109.53 244.64-244.64a1.8 1.8 0 0 0-0.39-1.11 1.91 1.91 0 0 0 0.8-0.41 370.43 370.43 0 0 0 126.18-270 389.59 389.59 0 0 0-195.46-52.25zM461 505.9l0.47-1.21h0.58z"
                    fill="#1DCE75" p-id="1491"></path>
                <path
                    d="M788.81 572.1c-178.69 0-323.55 144.9-323.55 323.54a324.69 324.69 0 0 0 5.81 61.19 246.45 246.45 0 0 0 40.93 3.43c135.11 0 244.64-109.53 244.64-244.64a1.8 1.8 0 0 0-0.39-1.11 1.91 1.91 0 0 0 0.8-0.41 372.35 372.35 0 0 0 97.45-135.3 324.55 324.55 0 0 0-65.69-6.7z"
                    fill="#05C46D" p-id="1492"></path>
                <path
                    d="M411.17 152.4c7.56 11.34-32.19 25.35-74.11 53.29s-70.16 59.22-77.72 47.88 15.66-50.21 57.56-78.14 86.72-34.37 94.27-23.03z"
                    fill="#FFFFFF" p-id="1493"></path>
              </svg>
              <n-text style="margin-left: 0.5em" type="success">
                我的创作
              </n-text>
            </template>
            <template #header-extra>
              <n-text type="success">
                创作总数：{{ userImgTotal }}
              </n-text>
            </template>
            <div>
              <div class="masonry">
                <div v-for="item in userImgList" :key="item.id" class="masonry-item-mobile">
                  <!-- 展示列表项的内容 -->
                  <img :title="item.id" style="height: 100%;border-radius: 10px;"
                       :src="item.pictureUrl===null || item.pictureUrl===''? defaultImg :item.pictureUrl"/>
                  <div class="img-bottom-mobile">
                    <n-tag v-if="item.taskStatus ===0" size="small" type="warning">
                      生成中
                    </n-tag>
                    <n-tag v-if="item.taskStatus ===2" size="small" type="error">
                      失败
                    </n-tag>
                    <n-button v-if="item.parentId==null && item.taskStatus !==2" tertiary size="tiny" type="info"
                              @click="handleEditPic(item)"
                    >
                      派生
                    </n-button>
                    <n-button tertiary size="tiny" type="info" @click="handleCopy(item.prompt)">
                      复制咒语
                    </n-button>
                    <n-button tertiary size="tiny" type="primary" @click="handleShare(item.id)">
                      {{ item.hasPublic === 0 ? '公开预览' : '取消公开' }}
                    </n-button>
                    <n-button tertiary size="tiny" type="error" @click="handleDel(item.id)">
                      删除
                    </n-button>
                  </div>
                </div>
              </div>
              <div v-if="isUserLoading" class="loading">
                Loading...
              </div>
              <n-pagination
                  v-if="userImgList.length!=0"
                  v-model:page="userPage"
                  class="pageClass"
                  :page-count="userTotal"
                  simple
                  @update:page="getPrivatePic()"
              />
            </div>
          </n-collapse-item>
          <n-collapse-item name="3">
            <template #header>
              <svg t="1689003386826" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="2140" width="16" height="16">
                <path
                    d="M913.75 790.85L724.61 327.06V136.15H751a35.91 35.91 0 0 0 35.91-35.91A35.91 35.91 0 0 0 751 64.33H273a35.91 35.91 0 0 0-35.91 35.91A35.91 35.91 0 0 0 273 136.15h24.1v190.5L110 791.37c-32.42 80.53 26.87 168.3 113.67 168.3h576.61c87.05 0 146.34-88.22 113.47-168.82zM362.2 776a65.1 65.1 0 1 1 64.33-65.87A65.1 65.1 0 0 1 362.2 776z m191.26-194.73a38.67 38.67 0 1 1 38.2-39.12 38.68 38.68 0 0 1-38.2 39.12z"
                    fill="#48E1AA" p-id="2141"></path>
                <path
                    d="M647 248.19c-157.33 0-298.07 71-391.91 182.78L136.63 725.29q-1.12 17.07-1.14 34.42A510.3 510.3 0 0 0 171.26 948a122 122 0 0 0 52.45 11.66h576.57c87.05 0 146.34-88.22 113.47-168.82L724.61 327.06V254a515.73 515.73 0 0 0-77.61-5.81zM362.2 776a65.1 65.1 0 1 1 64.33-65.87A65.1 65.1 0 0 1 362.2 776z m191.26-194.73a38.67 38.67 0 1 1 38.2-39.12 38.68 38.68 0 0 1-38.2 39.12z"
                    fill="#3CD38E" p-id="2142"></path>
                <path
                    d="M767 430.9a434 434 0 0 0-195.35 77.82 38.66 38.66 0 1 1-55 46.87A437.16 437.16 0 0 0 424 693a65.1 65.1 0 0 1-22.76 69.33 440 440 0 0 0-1.14 197.31h400.18c87.05 0 146.34-88.22 113.47-168.82z"
                    fill="#1DCE75" p-id="2143"></path>
                <path
                    d="M589.06 959.67h211.22c87.05 0 146.34-88.22 113.47-168.82l-77.49-190C699.28 666.27 601.75 801 589.06 959.67z"
                    fill="#05C46D" p-id="2144"></path>
                <path
                    d="M469.82 173.07c6.2 10.18-29 21-66.65 44s-63.4 49.3-69.61 39.12 15.42-43.33 53-66.28 77.05-27.02 83.26-16.84z"
                    fill="#FFFFFF" p-id="2145"></path>
              </svg>
              <n-text style="margin-left: 0.5em" type="success">
                控制台
              </n-text>
            </template>

            <div class="draw-config">
              <n-space vertical size="large">
                <n-steps v-model:current="currentStep" :on-update-current="resetDivStatus">
                  <n-step
                      title="控制台"
                  />
                  <n-step
                      title="任务"
                  />
                </n-steps>
                <div v-if="drawConfigDiv">
                  <div class="config">
                    <p class="config-title">
                      选择模型
                    </p>
                    <n-radio-group v-model:value="checkModel">
                      <n-radio-button value="5.2">
                        MJ-v5.2
                      </n-radio-button>
                      <n-radio-button value="5.1">
                        MJ-v5.1
                      </n-radio-button>
                      <n-radio-button value="5">
                        MJ-v5
                      </n-radio-button>
                      <n-radio-button value="niji 5">
                        Niji-v5
                      </n-radio-button>
                    </n-radio-group>
                    <p class="font-show-size" v-if="checkModel==='5'">
                      MidjourneyV5模型，修复了V4手部错乱等问题
                    </p>
                    <p class="font-show-size" v-if="checkModel==='5.1'">
                      MidjourneyV5.1模型，修复了V4手部错乱等问题
                    </p>
                    <p class="font-show-size" v-if="checkModel==='5.2'">
                      MidjourneyV5.2模型，最新版本
                    </p>
                    <p class="font-show-size" v-if="checkModel==='niji 5'">
                      最强二次元AI绘画模型，根据文字描述绘制二次元风的画作
                    </p>
                  </div>

                  <div class="config">
                    <p class="config-title">
                      图片比例
                    </p>
                    <n-radio-group v-model:value="checkRatio">
                      <n-radio-button value="1:1">
                        <div class="ratio ratio1-1"/>
                        1:1
                      </n-radio-button>
                      <n-radio-button value="4:3">
                        <div class="ratio ratio4-3"/>
                        4:3
                      </n-radio-button>
                      <n-radio-button value="3:4">
                        <div class="ratio ratio3-4"/>
                        3:4
                      </n-radio-button>
                      <n-radio-button value="16:9">
                        <div class="ratio ratio16-9"/>
                        16:9
                      </n-radio-button>
                      <n-radio-button value="9:16">
                        <div class="ratio ratio9-16"/>
                        9:16
                      </n-radio-button>
                    </n-radio-group>
                  </div>
                  <div class="config">
                    <p class="config-title">
                      绘画速率
                    </p>
                    <n-radio-group v-model:value="efficiency">
                      <n-radio-button value="relax">
                        轻松
                      </n-radio-button>
                      <n-radio-button value="fast">
                        快速
                      </n-radio-button>
                      <!--											<n-radio-button value="turbo" disabled>-->
                      <!--												极速-->
                      <!--											</n-radio-button>-->
                    </n-radio-group>
                    <p class="font-show-size" v-if="efficiency==='relax'">
                      轻松模式为低效率出图模型，平均出图时间约为3-5分钟
                    </p>
                    <p class="font-show-size" v-if="efficiency==='fast'">
                      拥有GPU服务器优先调度权，平均出图时间约为1分钟
                    </p>
                    <!--										<p class="font-show-size" v-if="efficiency==='turbo'">-->
                    <!--											该模式出图效率极高但使用费用高昂，测试期间暂不开放-->
                    <!--										</p>-->
                  </div>
                  <div class="config">
                    <span class="flex-shrink-0 w-[100px] ">抽象程度</span>
                    <div class="flex-1">
                      <NSlider
                          v-model:value="drawState.abstract" :keyboard="true" tooltip :marks="abstract" :min="0"
                          :max="100"
                          :step="1"
                      />
                      <p class="font-show-size">
                        抽象程度，会影响初始图像网格的变化，较高的值将产生更多不寻常和意想不到的结果和成分；较低的值具有更可靠、可重复的结果。
                      </p>
                    </div>
                  </div>

                  <div class="config">
                    <span class="flex-shrink-0 w-[100px] ">艺术程度</span>
                    <div class="flex-1">
                      <NSlider
                          v-model:value="drawState.art" :keyboard="true" tooltip :marks="art" :min="0" :max="1000"
                          :step="10"
                      />
                      <p class="font-show-size">
                        艺术程度，数值越高画面表现也会更具丰富性和艺术性
                      </p>
                    </div>
                  </div>

                  <div class="config">
                    <span class="flex-shrink-0 w-[100px] ">图片质量</span>
                    <div class="flex-1">
                      <NSlider
                          v-model:value="drawState.quality" :keyboard="true" tooltip :marks="quality" :min="0.25"
                          :max="2"
                          :step="0.1"
                      />
                      <p class="font-show-size">
                        图片质量，更高质量的设置需要更长的时间来处理和产生更多细节
                      </p>
                    </div>
                  </div>
                  <div class="config">
                    <span class="flex-shrink-0 w-[100px] ">垫图</span>
                    <div class="flex-1">
                      <n-upload
                          action="https://www.mocky.io/v2/5e4bafc63100007100d8b70f"
                          :default-file-list="previewFileList"
                          :on-update:file-list="handleGetBase64"
                          :on-remove="handleRemove"
                          list-type="image-card"
                          :show-retry-button="true"
                          :default-upload="false"
                          :max="1"
                          @preview="handlePreview"
                      />
                      <n-modal
                          v-model:show="showModal"
                          preset="card"
                          style="width: 600px"
                          title="一张很酷的图片"
                      >
                        <img :src="previewImageUrl" style="width: 100%">
                      </n-modal>
                    </div>
                    <p class="font-show-size">
                      垫图是一种参考图, 可以提供给MidJourney一些关于所需图像的更多信息，从而生成更符合我们期望的图像。
                    </p>
                  </div>

                  <div class="config">
                    <span class="flex-shrink-0 w-[100px] ">创作灵感</span>
                    <n-input
                        v-model:value="drawState.originalPrompt"
                        placeholder="描述你的创作灵感，如:“一只金毛狗在草坪上开心的玩耍”。可通过AI来优化更多的细节。"
                        type="textarea"
                        size="small"
                        :autosize="{
              minRows: 3,
              maxRows: 5,
            }"
                    />
                    <div class="config-btns">
                      <n-button strong secondary round type="primary" size="small" :disabled="isEnabledHzy"
                                @click="handleAiOptimize"
                      >
                        优化细节
                      </n-button>
                      <n-button strong secondary round type="primary" size="small" :disabled="isEnabledTranslate"
                                @click="handleTranslate"
                      >
                        翻译咒语
                      </n-button>
                    </div>
                  </div>
                  <div class="config">
                    <n-input
                        v-model:value="drawState.drawPrompt"
                        placeholder="本内容由AI自动生成的，当然你也可以主动编辑或跳过AI优化直接替换，直接替换请使用务必使用英文咒语！"
                        type="textarea"
                        size="small"
                        :autosize="{
												minRows: 3,
												maxRows: 5,
											}"
                        @input="checkString"
                    />
                    <div class="config-btns">
                      <n-button strong secondary icon-placement="right" round type="warning"
                                @click="handleRunDrawMethod">
                        <!--												<template #icon>-->
                        <!--													<n-icon>-->
                        <!--														<svg t="1699629934065" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14103" width="64" height="64"><path d="M275.687675 288.499644 216.653254 288.499644 216.653254 226.863686 393.858893 226.863686 393.858893 473.46774 630.092931 226.863686 807.35879 226.863686 275.687675 781.67162Z" p-id="14104" fill="#d5cc23"></path></svg>-->
                        <!--													</n-icon>-->
                        <!--												</template>-->
                        生成
                      </n-button>
                      <p class="font-show-size-tip">
                        目前普通用户每日可以使用5次绘画，会员无限次。
                      </p>
                      <p class="font-show-size-tip">
                        也可通过新建问答->点击上方模型名称->选择[AI绘画[Dall·E-3]模型]创建绘画作业。[需要GPT4.0会员或者4.0次数权限]
                      </p>
                    </div>
                  </div>
                </div>
                <div v-else class="img-outer">
                  <div v-if="hasRunTask">
                    <div class="img-box">
                      <img :src="picUrl==''?defaultImg:picUrl">
                      <n-progress
                          v-if="showProgress"
                          type="line"
                          :percentage="Number(progress)"
                          :indicator-placement="'inside'"
                          processing
                          color="green"
                      />
                    </div>
                    <div v-if="showOperateDiv">
                      <div class="config">
                        <span class="flex-shrink-0 w-[100px] ">派生</span>
                        <div class="flex-1">
                          <p class="font-show-size">
                            选择以上图片序列以生成4张画面接近的图片
                          </p>
                          <p class="font-show-size">如：V1、V2分别代表上左、上右; V3、V4分别代表下左、下右</p>

                          <n-grid y-gap="12" :cols="4">

                            <n-gi>
                              <n-button class="split-img" tertiary type="primary" size="small"
                                        @click="handleRebuild(1)">
                                V1
                              </n-button>
                            </n-gi>
                            <n-gi>
                              <n-button class="split-img" tertiary type="primary" size="small"
                                        @click="handleRebuild(2)">
                                V2
                              </n-button>
                            </n-gi>
                            <n-gi>
                              <n-button class="split-img" tertiary type="primary" size="small"
                                        @click="handleRebuild(3)">
                                V3
                              </n-button>
                            </n-gi>
                            <n-gi>
                              <n-button class="split-img" tertiary type="primary" size="small"
                                        @click="handleRebuild(4)">
                                V4
                              </n-button>
                            </n-gi>

                          </n-grid>
                        </div>
                      </div>
                      <div class="config">
                        <span class="flex-shrink-0 w-[100px] ">放大</span>
                        <div class="flex-1">
                          <p class="font-show-size">
                            选择以上图片序号进行放大以生成最清晰画质图片
                          </p>
                          <p class="font-show-size">如：U1、U2分别代表上左、上右; U3、U4分别代表下左、下右</p>
                          <n-grid y-gap="12" :cols="4">
                            <n-gi>
                              <n-button class="split-img" tertiary type="primary" size="small"
                                        @click="handleRebuild(5)">
                                U1
                              </n-button>
                            </n-gi>
                            <n-gi>
                              <n-button class="split-img" tertiary type="primary" size="small"
                                        @click="handleRebuild(6)">
                                U2
                              </n-button>
                            </n-gi>
                            <n-gi>
                              <n-button class="split-img" tertiary type="primary" size="small"
                                        @click="handleRebuild(7)">
                                U3
                              </n-button>
                            </n-gi>
                            <n-gi>
                              <n-button class="split-img" tertiary type="primary" size="small"
                                        @click="handleRebuild(8)">
                                U4
                              </n-button>
                            </n-gi>
                          </n-grid>
                        </div>
                      </div>
                      <div class="img-btns">
                        <n-button class="create-new" tertiary type="primary" size="small" @click="handleRebuild(9)">
                          整体重绘
                        </n-button>
                      </div>
                    </div>
                    <div v-else style="margin-top: 0.5em">
                      <p class="font-show-size">
                        绘制中，请稍后~
                      </p>
                      <p class="font-show-size">
                        影响速率的因素有很多
                      </p>
                      <p class="font-show-size">
                        例如:本服务同时使用的人数较多导致排队、设置较高的质量需要更长的时间来处理和产生更多细节、图片较大(产生的图像通常是7-10MB)
                      </p>
                      <p class="font-show-size">
                        出图通常需要1-5分钟左右，若你关闭了本页面，也可在我的创作中找到这个画作
                      </p>
                    </div>
                  </div>
                  <div v-else>
                    <div style="display: flex;align-items: center;height:45em;">
                      <span style="text-align: center;margin:0 auto">暂无进行中的任务</span>
                    </div>
                  </div>
                </div>
              </n-space>
            </div>
          </n-collapse-item>
        </n-collapse>
      </div>

    </div>

    <div class="draw-config" v-if="!isMobile">
      <n-space vertical size="large">
        <n-steps v-model:current="currentStep" :on-update-current="resetDivStatus">
          <n-step
              title="控制台"
          />
          <n-step
              title="任务"
          />
        </n-steps>
        <div v-if="drawConfigDiv">
          <div class="config">
            <p class="config-title">
              选择模型
            </p>
            <n-radio-group v-model:value="checkModel">
              <n-radio-button value="5.2">
                MJ-v5.2
              </n-radio-button>
              <n-radio-button value="5.1">
                MJ-v5.1
              </n-radio-button>
              <n-radio-button value="5">
                MJ-v5
              </n-radio-button>
              <n-radio-button value="niji 5">
                Niji-v5
              </n-radio-button>
            </n-radio-group>
            <p class="font-show-size" v-if="checkModel==='5'">
              MidjourneyV5模型，修复了V4手部错乱等问题
            </p>
            <p class="font-show-size" v-if="checkModel==='5.1'">
              MidjourneyV5.1模型，修复了V4手部错乱等问题
            </p>
            <p class="font-show-size" v-if="checkModel==='5.2'">
              MidjourneyV5.2模型，最新版本
            </p>
            <p class="font-show-size" v-if="checkModel==='niji 5'">
              最强二次元AI绘画模型，根据文字描述绘制二次元风的画作
            </p>
          </div>

          <div class="config">
            <p class="config-title">
              图片比例
            </p>
            <n-radio-group v-model:value="checkRatio">
              <n-radio-button value="1:1">
                <div class="ratio ratio1-1"/>
                1:1
              </n-radio-button>
              <n-radio-button value="4:3">
                <div class="ratio ratio4-3"/>
                4:3
              </n-radio-button>
              <n-radio-button value="3:4">
                <div class="ratio ratio3-4"/>
                3:4
              </n-radio-button>
              <n-radio-button value="16:9">
                <div class="ratio ratio16-9"/>
                16:9
              </n-radio-button>
              <n-radio-button value="9:16">
                <div class="ratio ratio9-16"/>
                9:16
              </n-radio-button>
            </n-radio-group>
          </div>
          <div class="config">
            <p class="config-title">
              绘画速率
            </p>
            <n-radio-group v-model:value="efficiency">
              <n-radio-button value="relax">
                轻松
              </n-radio-button>
              <n-radio-button value="fast">
                快速
              </n-radio-button>
              <!--							<n-radio-button value="turbo" disabled>-->
              <!--								极速-->
              <!--							</n-radio-button>-->
            </n-radio-group>
            <p class="font-show-size" v-if="efficiency==='relax'">
              轻松模式为低效率出图模型，平均出图时间约为3-5分钟
            </p>
            <p class="font-show-size" v-if="efficiency==='fast'">
              拥有GPU服务器优先调度权，平均出图时间约为1分钟
            </p>
            <!--						<p class="font-show-size" v-if="efficiency==='turbo'">-->
            <!--							该模式出图效率极高但使用费用高昂，测试期间暂不开放-->
            <!--						</p>-->
          </div>
          <div class="config">
            <span class="flex-shrink-0 w-[100px] ">抽象程度</span>
            <div class="flex-1">
              <NSlider
                  v-model:value="drawState.abstract" :keyboard="true" tooltip :marks="abstract" :min="0" :max="100"
                  :step="1"
              />
              <p class="font-show-size">
                抽象程度，会影响初始图像网格的变化，较高的值将产生更多不寻常和意想不到的结果和成分；较低的值具有更可靠、可重复的结果。
              </p>
            </div>
          </div>

          <div class="config">
            <span class="flex-shrink-0 w-[100px] ">艺术程度</span>
            <div class="flex-1">
              <NSlider
                  v-model:value="drawState.art" :keyboard="true" tooltip :marks="art" :min="0" :max="1000"
                  :step="10"
              />
              <p class="font-show-size">
                艺术程度，数值越高画面表现也会更具丰富性和艺术性
              </p>
            </div>
          </div>

          <div class="config">
            <span class="flex-shrink-0 w-[100px] ">图片质量</span>
            <div class="flex-1">
              <NSlider
                  v-model:value="drawState.quality" :keyboard="true" tooltip :marks="quality" :min="0.25" :max="2"
                  :step="0.1"
              />
              <p class="font-show-size">
                图片质量，更高质量的设置需要更长的时间来处理和产生更多细节
              </p>
            </div>
          </div>
          <div class="config">
            <span class="flex-shrink-0 w-[100px] ">垫图</span>
            <div class="flex-1">
              <n-upload
                  action="https://www.mocky.io/v2/5e4bafc63100007100d8b70f"
                  :default-file-list="previewFileList"
                  :on-update:file-list="handleGetBase64"
                  :on-remove="handleRemove"
                  list-type="image-card"
                  :show-retry-button="true"
                  :default-upload="false"
                  :max="1"
                  @preview="handlePreview"
              />
              <n-modal
                  v-model:show="showModal"
                  preset="card"
                  style="width: 600px"
                  title="一张很酷的图片"
              >
                <img :src="previewImageUrl" style="width: 100%">
              </n-modal>
            </div>
            <p class="font-show-size">
              垫图是一种参考图,可以提供给MidJourney一些关于所需图像的更多信息，从而生成更符合我们期望的图像。
            </p>
          </div>

          <div class="config">
            <span class="flex-shrink-0 w-[100px] ">创作灵感</span>
            <n-input
                v-model:value="drawState.originalPrompt"
                placeholder="描述你的创作灵感，如:“一只金毛狗在草坪上开心的玩耍”。可通过AI来优化更多的细节。"
                type="textarea"
                size="small"
                :autosize="{
              minRows: 3,
              maxRows: 5,
            }"
            />
            <div class="config-btns">
              <n-button strong secondary round type="primary" size="small" :disabled="isEnabledHzy"
                        @click="handleAiOptimize"
              >
                优化细节
              </n-button>
              <n-button strong secondary round type="primary" size="small" :disabled="isEnabledTranslate"
                        @click="handleTranslate"
              >
                翻译咒语
              </n-button>
            </div>
          </div>
          <div class="config">
            <n-input
                v-model:value="drawState.drawPrompt"
                placeholder="本内容由AI自动生成的，当然你也可以主动编辑或跳过AI优化直接替换，直接替换请使用务必使用英文咒语！"
                type="textarea"
                size="small"
                :autosize="{
              minRows: 3,
              maxRows: 5,
            }"
                @input="checkString"
            />
            <div class="config-btns">
              <n-button strong secondary icon-placement="right" round type="warning" @click="handleRunDrawMethod">
                <!--								<template #icon>-->
                <!--									<n-icon>-->
                <!--										<svg t="1699629934065" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14103" width="64" height="64"><path d="M275.687675 288.499644 216.653254 288.499644 216.653254 226.863686 393.858893 226.863686 393.858893 473.46774 630.092931 226.863686 807.35879 226.863686 275.687675 781.67162Z" p-id="14104" fill="#d5cc23"></path></svg>-->
                <!--									</n-icon>-->
                <!--								</template>-->
                生成
              </n-button>
              <p class="font-show-size-tip">
                目前普通用户每日可以使用5次绘画，会员无限次。
              </p>
              <p class="font-show-size-tip">
                也可通过新建问答->点击上方模型名称->选择[AI绘画[Dall·E-3]模型]创建绘画作业。[需要GPT4.0会员或者4.0次数权限]
              </p>
            </div>
          </div>
        </div>
        <div v-else class="img-outer">
          <!--					hasRunTask-->
          <div v-if="hasRunTask">
            <div class="img-box">
              <img :src="picUrl==''?defaultImg:picUrl">
              <n-progress
                  v-if="showProgress"
                  type="line"
                  :percentage="Number(progress)"
                  :indicator-placement="'inside'"
                  processing
                  color="green"
              />
            </div>
            <div v-if="showOperateDiv">
              <div class="config">
                <span class="flex-shrink-0 w-[100px] ">派生</span>
                <div class="flex-1">
                  <p class="font-show-size">
                    选择以上图片序列以生成4张画面接近的图片
                  </p>
                  <p class="font-show-size">如：V1、V2分别代表上左、上右; V3、V4分别代表下左、下右</p>

                  <n-grid y-gap="12" :cols="4">

                    <n-gi>
                      <n-button class="split-img" tertiary type="primary" size="small" @click="handleRebuild(1)">
                        V1
                      </n-button>
                    </n-gi>
                    <n-gi>
                      <n-button class="split-img" tertiary type="primary" size="small" @click="handleRebuild(2)">
                        V2
                      </n-button>
                    </n-gi>
                    <n-gi>
                      <n-button class="split-img" tertiary type="primary" size="small" @click="handleRebuild(3)">
                        V3
                      </n-button>
                    </n-gi>
                    <n-gi>
                      <n-button class="split-img" tertiary type="primary" size="small" @click="handleRebuild(4)">
                        V4
                      </n-button>
                    </n-gi>

                  </n-grid>
                </div>
              </div>
              <div class="config">
                <span class="flex-shrink-0 w-[100px] ">放大</span>
                <div class="flex-1">
                  <p class="font-show-size">
                    选择以上图片序号进行放大以生成最清晰画质图片
                  </p>
                  <p class="font-show-size">如：U1、U2分别代表上左、上右; U3、U4分别代表下左、下右</p>
                  <n-grid y-gap="12" :cols="4">
                    <n-gi>
                      <n-button class="split-img" tertiary type="primary" size="small" @click="handleRebuild(5)">
                        U1
                      </n-button>
                    </n-gi>
                    <n-gi>
                      <n-button class="split-img" tertiary type="primary" size="small" @click="handleRebuild(6)">
                        U2
                      </n-button>
                    </n-gi>
                    <n-gi>
                      <n-button class="split-img" tertiary type="primary" size="small" @click="handleRebuild(7)">
                        U3
                      </n-button>
                    </n-gi>
                    <n-gi>
                      <n-button class="split-img" tertiary type="primary" size="small" @click="handleRebuild(8)">
                        U4
                      </n-button>
                    </n-gi>
                  </n-grid>
                </div>
              </div>
              <div class="img-btns">
                <n-button class="create-new" tertiary type="primary" size="small" @click="handleRebuild(9)">
                  整体重绘
                </n-button>
              </div>
            </div>
            <div v-else style="margin-top: 0.5em">
              <p style="color: #999999;font-size: 10px;">
                绘制中，请稍后~
              </p>
              <p class="font-show-size">
                影响速率的因素有很多
              </p>
              <p class="font-show-size">
                例如:本服务同时使用的人数较多导致排队、设置较高的质量需要更长的时间来处理和产生更多细节、图片较大(产生的图像通常是7-10MB)
              </p>
              <p class="font-show-size">
                出图通常需要1-5分钟左右，若你关闭了本页面，也可在我的创作中找到这个画作
              </p>
            </div>
          </div>
          <div v-else>
            <div style="display: flex;align-items: center;height:45em;">
              <span style="text-align: center;margin:0 auto">暂无进行中的任务</span>
            </div>
          </div>
        </div>
      </n-space>
    </div>
  </div>
</template>

<style scoped>
.masonry {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 36em;
  justify-content: space-around;
  margin-bottom: 0.5em;
  padding-bottom: 12px;
  width: 100%;
}

.masonry-item {
  width: calc(33.33% - 20px);
  margin: 0.2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.masonry-item-mobile {
  width: 100%;
  margin: 0.2em;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.img-bottom {
  margin-top: 0.2em;
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.img-bottom-mobile {
  margin-top: 0.2em;
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.loading {
  text-align: center;
  margin-top: 20px;
}

.draw-box {
  padding: 0.5em;
  height: 100%;
}

.draw-box > div {
  background: #ffffff;
  padding: 1em;

}

.draw-list {
  width: 65%;
  margin-right: 0.8em;

}

.draw-config {
  flex: 5;
  overflow: auto;
}

.n-radio-button {
  width: 5em !important;
}

.n-radio__label {
  display: flex;
  align-items: center;
}

.ratio {
  border: 1px solid #999999;
  border-radius: 5px;
  text-align: center;
  display: inline-block;

}

.pageClass {
  bottom: 0;
  justify-content: center;
  height: 5em;
  width: 100%;
}

.ratio1-1 {
  height: 1em;
  width: 1em;
  margin: 0 auto;
}

.ratio4-3 {
  height: 0.9em;
  width: 1.2em;
  margin: 0 auto;
}

.ratio3-4 {
  height: 1.2em;
  width: 0.9em;
  margin: -2% auto;
}

.ratio16-9 {
  height: 0.9em;
  width: 1.6em;
  margin: 0 auto;
}

.ratio9-16 {
  height: 1.6em;
  width: 0.9em;
  margin: -10% auto;
}

.config {
  margin-top: 10px;
}

.img-outer {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.img-box {
  display: block;
  height: 60%;
  width: 100%;
}

.img-box img {
  width: 100%;
  height: 100%;
}

.img-btns {
  padding: 1em;
  flex: 1;
  text-align: center;
}

.create-new {
  margin-top: 2em;
}

.split-img {
  min-width: 3em;
}

.config-btns {
  margin: 0.5em auto;
}

.config-btns .n-button {
  margin: 0 0.5em;
}

.carousel-img {
  width: 100%;
  height: 20em;
  object-fit: cover;
  padding: 0.6em;
}

.font-show-size {
  color: #999999;
  font-size: 12px;
}

.font-show-size-tip {
  color: #999999;
  font-size: 10px;
}
</style>
